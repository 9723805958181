export const variant_colors: string[] = [
  "Red",
  "Black",
  "Blue",
  "White/Silver",
  "Space Gray/Black",
  "Silver/White",
  "Aura Glow",
  "Aura Black",
  "Aura Red",
  "Prism",
  "Prism White",
  "Prism Black",
  "Prism Blue",
  "Prism Crush Red",
  "Prism Crush White",
  "Prism Crush Black",
  "Crush Black",
  "Silver/White",
  "White",
  "Gold",
  "Sky blue",
  "White",
  "Pink",
  "Yellow",
  "Purple",
  "Green",
  "Space Gray",
  "Silver",
  "Midnight Green",
  "Space Black",
  "Coral",
  "Rose gold",
  "Jet Black",
  "Graphite",
  "Midnight",
  "Starlight",
  "Sierra Blue",
  "Teal",
  "Ultramarine",
  "Pink",
  "Natural Titanium",
  "Desert Titanium",
  "White Titanium",
  "Black Titanium",
];
